<template>
  <section id="card-images">
    <b-row>
      <b-col
        v-for="item in options"
        :key="item.name"
        md="4"
        lg="3"
        xl="2"
      >
        <router-link
          class="category__link"
          :to="'category/'+item.slug"
        >
          <b-card
            border-variant="primary"
            class="mb-0"
          ><progressive-img
            v-bind:src="`https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_id}.png`"
            fallback="https://f004.backblazeb2.com/file/brime-assets/missing_cover.png"
          /><span
            id="streamTitle"
          >{{ item.name }}</span>
            <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'

import Vue from 'vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const axios = require('axios')

const prefix = 'https://content.brimecdn.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      options: {},
      test: {},
      prefix,
      suffix,
    }
  },
  mounted() {
    document.title = 'Live Categories - Brime'
  },
  async beforeCreate() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    axios.get('https://api.brime.tv/v1/categories/live')
      .then(response => {
        // handle success
        function unique(arr) {
          const xids = []
          return arr.filter(e => {
            if (xids.includes(e.slug)) return false
            if(!e.slug) return false
            xids.push(e.slug)
            return true
          })
        }
        const categories = response.data
        this.options = unique(categories)
      })
      .catch(() => {
        // handle error
      })
      .then(() => {
        // always executed
      })
  },
}
</script>
<style scoped>
/* Scrollbar styles */
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card-body {
  padding: 0.5rem;
}

.card {
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  transition: all 0.2s;
}

.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}

.card img {
  transition: filter 0.2s;
  filter: saturate(0.65);
}

.card:hover img {
  filter: saturate(1)
}

.category__link {
  display: block;
  height: 100%;
  padding-bottom: 2.5rem;
}

.category__link .card {
  height: 100%;
}

.category__link img {
  flex: 1;
}

.category__link #streamTitle {
  margin: 0;
}

.category__link .card-body {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem;
}

#category {
  color: #b4b7bd;
}
#streamTitle {
  font-size: 14px;
  text-overflow: ellipsis;
}
#stream {
  display: block;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 10px;
}
#streamTitle {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
    line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
